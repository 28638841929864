@import "normalise.css";
@import "base";
@import "zenburn.css";

// site-wide
body {
    background: $backgroundColor;
    color: $bodyColor;
    font-size: 16px;
    font-family: 'Helvetica';
    padding: 0.5em;
    padding-left: 1em;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.3em;
}

a:visited {
    color: blue
}

.content {
    max-width: 500px;
}

.nav {
    margin-right: 3em;
}

.nav .name {
    margin-bottom: 0.25em;
}

.nav .navlinks {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.nav .navlinks li {
    margin-bottom: 0.25em;
}

pre.highlight {
    padding: 0.5em;
}

h1 {
    font-size: 1em;
    line-height: 1.5em;
}

h1 + p {
    margin-top: 0;
}

@media (min-width:480px) {
    content {
        max-width: 600px;
    }
}

// stream

.note-header {
    margin-bottom: 0;
}

.note-content p:first-child {
    margin-top: 0;
}

.note-header a {
    color: black;
    text-decoration: none;
}